/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W styczniu 2024 roku podpisano umowę na realizację inwestycji rozbudowy składowiska odpadów w Sulnówku o nową kwaterę."), "\n", React.createElement(_components.p, null, "W ramach robót zostały zaplanowane między innymi następujące czynności:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "budowa nowej kwatery IV o powierzchni ponad 4 ha,"), "\n", React.createElement(_components.li, null, "wykonanie dróg technologicznych dla samochodów dowożących odpady i sprzętu technologicznego do kwatery oraz poboczy dla wjazdu kompaktora,"), "\n", React.createElement(_components.li, null, "ułożenie drenażu z rur perforowanych na dnie i skarpach, wykonanie szczelnych przejść przez uszczelnienie z folii PEHD skarpy,"), "\n", React.createElement(_components.li, null, "wykonanie rurociągu i studni połączeniowej poza obrysem uszczelnionej kwatery,"), "\n", React.createElement(_components.li, null, "wykonanie głównej drogi technologicznej i pożarowej wzdłuż kwatery składowiska,"), "\n", React.createElement(_components.li, null, "wykonanie studni odgazowujących, które zostaną zabudowane w dnie składowiska i zakończone przy powierzchni korony,"), "\n", React.createElement(_components.li, null, "wykonanie oświetlenia korony niecki,"), "\n", React.createElement(_components.li, null, "wykonanie pasa zieleni izolacyjnej,"), "\n", React.createElement(_components.li, null, "wykonanie ogrodzenia kwatery z siatki metalowej na słupach."), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany jest na 10 miesięcy. Inwestycja planowo powinna być oddana do użytku jeszcze w roku 2024."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
